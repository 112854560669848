import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../components/Home.vue"),
  },
  {
    path: "/cotizaciones",
    component: () => import("../components/dummy"),
    children: [
      {
        path: "",
        name: "Cotizaciones",
        component: () => import("../components/Cotizaciones"),
      },
      {
        path: "autorizar",
        name: "Cotizaciones",
        component: () => import("../components/Cotizaciones_vb"),
      },
      {
        path: "agregar",
        name: "Nueva_cotizacion",
        component: () => import("../components/Cotizacion"),
      },
    ],
  },
  {
    path: "/informes",
    component: () => import("../components/dummy"),
    children: [
      {
        path: "",
        name: "Informes",
        component: () => import("../components/InfSema"),
      },
      {
        path: "agregar",
        name: "Nuevo_informe",
        component: () => import("../components/newinf"),
      },
    ],
  },
  {
    path: "/solicitudes",
    component: () => import("../components/dummy"),
    children: [
      {
        path: "",
        name: "Solicitudes",
        component: () => import("../components/SPClist"),
      },
      {
        path: "agregar",
        name: "Nueva_solicitud",
        component: () => import("../components/newspc"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("../components/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
