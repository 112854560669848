<template>
  <v-app>
    <!-- v-if="!$route.meta.hideNavbar" -->
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      v-if="!tercero"
      :src="require('./assets/fondo-login.jpg')"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-img :src="require('./assets/logo.svg')"></v-img>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list shaped dense>
        <v-list-item-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Cotizaciones</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/cotizaciones">
            <v-list-item-icon
              ><v-icon>mdi-text-box-multiple-outline</v-icon></v-list-item-icon
            >
            <v-list-item-title>Listado cotizaciones</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/cotizaciones/agregar">
            <v-list-item-icon
              ><v-icon>mdi-text-box-plus-outline</v-icon></v-list-item-icon
            >
            <v-list-item-title>Agregar cotizacion</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/cotizaciones/autorizar">
            <v-list-item-icon
              ><v-icon>mdi-text-box-check-outline</v-icon></v-list-item-icon
            >
            <v-list-item-title>Autorizar cotizacion</v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <v-list-item link to="/informes">
          <v-list-item-icon
            ><v-icon
              >mdi-file-document-multiple-outline</v-icon
            ></v-list-item-icon
          >
          <v-list-item-title>Informes</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/informes/agregar">
          <v-list-item-icon
            ><v-icon>mdi-file-plus-outline</v-icon></v-list-item-icon
          >
          <v-list-item-title>Agregar informes</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/Solicitudes">
          <v-list-item-icon><v-icon>mdi-cogs</v-icon></v-list-item-icon>
          <v-list-item-title>Solicitudes</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logOut()">
          <v-list-item-icon
            ><v-icon>mdi-account-off-outline</v-icon></v-list-item-icon
          >
          Salir
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app dark flat :src="require('./assets/banner.jpg')">
      <v-app-bar-nav-icon @click="drawer = true" />

      <v-toolbar-title>
        {{ titulo }}
      </v-toolbar-title>
      <v-spacer> </v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer padless>
      <v-col
        class="text-center"
        cols="12"
        :src="require('./assets/banner.jpg')"
      >
        <strong>Ariztía - Depto. Mantenimiento</strong>
        <br />{{ new Date().getFullYear() }} — v0.0.5α — @BenAliSoft
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import eventBus from "./EventBus";
export default {
  name: "app",
  data: () => ({
    drawer: false,
    verpai: false,
    verrole: false,
    tercero: false,
    titulo: "",
  }),
  watch: {
    "$store.state.auth.status.loggedIn": function () {
      let user = JSON.parse(sessionStorage.getItem("user"));
      if (user) {
        (this.verpai = user.planta.includes("paico")),
          (this.verrole = user.roles.includes("ROLE_HYPERVISOR"));
        this.tercero = user.roles.includes("ROLE_TERCERO");
      }
    },
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      (this.verpai = user.planta.includes("paico")),
        (this.verrole = user.roles.includes("ROLE_HYPERVISOR"));
      this.tercero = user.roles.includes("ROLE_TERCERO");
    }
  },
  computed: {},
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    // let user = JSON.parse(sessionStorage.getItem("user"));
    // if (!user) {
    //   this.$router.push("/login");
    // }
    eventBus.$on("title-select", (val) => {
      this.titulo = val;
    });
  },
};
</script>
